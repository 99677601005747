import * as kot from 'adaptify-multi-module-rating-admin-model';
import {SERVER_BASE_URL} from '../../common/service/Constants';
import {
  ConvertToQueryParams,
  CreateStandardHeaders,
  HandleFetchJson,
  HandleFetchText,
  HandleFetchVoid,
  HttpError,
} from '../../common/service/Service';
import {
  IdAndName,
  Product,
  ProductLobInfo,
  ProductQuery,
  ProductSummary,
  ProductVersion,
  ProductVersionActivityHistory,
  ProductVersionCoverage,
  ProductVersionCoverageField,
  ProductVersionFlowInfo,
  ProductVersionNote,
  ProductVersionQuery,
  ProductVersionSummary,
  ProductVersionTableMetadataSummary,
  TestCaseInfo,
} from '../model/Product';
import Calculation = kot.com.adaptify.rating.admin.model.calculation.Calculation;
import Table = kot.com.adaptify.rating.admin.model.table.Table;
import TableDefinition = kot.com.adaptify.rating.admin.model.table.TableDefinition;
import Flow = kot.com.adaptify.rating.admin.model.flow.Flow;
import TestCase = kot.com.adaptify.rating.admin.model.testcase.TestCase;
import LineOfBusinessHierarchy = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy;

const SERVER_URL = SERVER_BASE_URL;

const QUERY_PRODUCT_URL = (productQuery: ProductQuery) =>
  `${SERVER_URL}/admin/prod/product/query?${ConvertToQueryParams(productQuery)}`;
const GET_PRODUCT_URL = (id: string) =>
  `${SERVER_URL}/admin/prod/product/get/${id}`;
const CREATE_PRODUCT_URL = `${SERVER_URL}/admin/prod/product/create`;
const SAVE_PRODUCT_URL = `${SERVER_URL}/admin/prod/product/update`;
const DELETE_PRODUCT_URL = (id: string) =>
  `${SERVER_URL}/admin/prod/product/delete/${id}`;

const UPDATE_PRODUCT_AND_VERSION_URL = `${SERVER_URL}/admin/prod/productandversion/update`;

const QUERY_PRODUCT_VERSION_BY_PRODUCT_URL = (productId: string) =>
  `${SERVER_URL}/admin/prod/productversion/query/byproduct?productId=${encodeURI(productId)}`;
const QUERY_PRODUCT_VERSION_SUMMARY_BY_ID_URL = (id: string) =>
  `${SERVER_URL}/admin/prod/productversionsummary/querybyid?id=${encodeURI(id)}`;
const QUERY_PRODUCT_VERSION_SUMMARY_URL = (query: ProductVersionQuery) =>
  `${SERVER_URL}/admin/prod/productversionsummary/query?${ConvertToQueryParams(query)}`;

const GET_PRODUCT_VERSION_URL = (id: string) =>
  `${SERVER_URL}/admin/prod/productversion/get/${id}`;
const SAVE_PRODUCT_VERSION_URL = `${SERVER_URL}/admin/prod/productversion/update`;
const DELETE_PRODUCT_VERSION_URL = (id: string) =>
  `${SERVER_URL}/admin/prod/productversion/delete/${id}`;
const COPY_PRODUCT_VERSION_URL = `${SERVER_URL}/admin/prod/productversion/copy`;
const COPY_PRODUCT_VERSION_NEW_EFFECTIVE_DATE_URL = `${SERVER_URL}/admin/prod/productversion/copyneweffectivedate`;
const COPY_PRODUCT_VERSION_NEW_PRODUCT_URL = `${SERVER_URL}/admin/prod/productversion/copynewproduct`;
const PUBLISH_PRODUCT_VERSION_URL = `${SERVER_URL}/admin/prod/productversion/publish`;
const QUERY_PRODUCT_VERSION_COVERAGE_URL = (productVersionId: string) =>
  `${SERVER_URL}/admin/prod/productversioncoverage/query?productId=${encodeURI(productVersionId)}`;
const GET_PRODUCT_VERSION_COVERAGE_URL = (id: string) =>
  `${SERVER_URL}/admin/prod/productversioncoverage/get/${id}`;
const SAVE_PRODUCT_VERSION_COVERAGE_URL = `${SERVER_URL}/admin/prod/productversioncoverage/update`;
const DELETE_PRODUCT_VERSION_COVERAGE_URL = (id: string) =>
  `${SERVER_URL}/admin/prod/productversioncoverage/delete/${id}`;

const QUERY_PRODUCT_VERSION_COVERAGE_FIELD_URL = (coverageId: string) =>
  `${SERVER_URL}/admin/prod/productversioncoveragefield/query?coverageId=${encodeURI(coverageId)}`;
const GET_PRODUCT_VERSION_COVERAGE_FIELD_URL = (id: string) =>
  `${SERVER_URL}/admin/prod/productversioncoverage/get/${id}`;
const SAVE_PRODUCT_VERSION_COVERAGE_FIELD_URL = `${SERVER_URL}/admin/prod/productversioncoverage/update`;
const DELETE_PRODUCT_VERSION_COVERAGE_FIELD_URL = (id: string) =>
  `${SERVER_URL}/admin/prod/productversioncoverage/delete/${id}`;

const QUERY_PRODUCT_VERSION_NOTE_URL = (productVersionId: string) =>
  `${SERVER_URL}/admin/prod/productversionnote/query?productVersionId=${encodeURI(productVersionId)}`;
const GET_PRODUCT_VERSION_NOTE_URL = (id: string) =>
  `${SERVER_URL}/admin/prod/productversionnote/get/${id}`;
const SAVE_PRODUCT_VERSION_NOTE_URL = `${SERVER_URL}/admin/prod/productversionnote/update`;
const DELETE_PRODUCT_VERSION_NOTE_URL = (id: string) =>
  `${SERVER_URL}/admin/prod/productversionnote/delete/${id}`;

const QUERY_PRODUCT_VERSION_TABLE_NAMES_URL = (productVersionId: string) =>
  `${SERVER_URL}/admin/prod/productversiontable/query?productVersionId=${encodeURI(productVersionId)}`;
const GET_PRODUCT_VERSION_TABLE_URL = (id: string) =>
  `${SERVER_URL}/admin/prod/productversiontable/get/${id}`;
const GET_PRODUCT_VERSION_TABLE_WITHOUT_ROWS_URL = (id: string) =>
  `${SERVER_URL}/admin/prod/productversiontable/getwithoutrows/${id}`;
const SAVE_PRODUCT_VERSION_TABLE_URL = `${SERVER_URL}/admin/prod/productversiontable/update`;
const DELETE_PRODUCT_VERSION_TABLE_URL = (id: string) =>
  `${SERVER_URL}/admin/prod/productversiontable/delete/${id}`;

const QUERY_PRODUCT_VERSION_TABLE_NAME_DOCUMENT_TABLE_METADATA_URL = (
  productVersionTableId: string
) =>
  `${SERVER_URL}/admin/prod/productversiontablemetadatasummary/querybyproductversiontableid?productVersionTableId=${encodeURI(productVersionTableId)}`;

const QUERY_ACTIVITY_HISTORY_URL = (productVersionId: string) =>
  `${SERVER_URL}/admin/prod/activityhistory/querybyproductversionid?productVersionId=${encodeURI(productVersionId)}`;

const QUERY_PRODUCT_VERSION_FLOW_NAMES_LOB_URL = (
  productVersionId: string,
  lobItemId: string
) =>
  `${SERVER_URL}/admin/prod/productversionflow/queryflownames?productVersionId=${encodeURI(productVersionId)}&lineOfBusinessItemId=${encodeURI(lobItemId)}`;

const QUERY_PRODUCT_VERSION_FLOW_BY_VERSION_LOB_URL = (
  productVersionId: string,
  lobItemId: string
) =>
  `${SERVER_URL}/admin/prod/productversionflow/querybyversionandlobitem?productVersionId=${encodeURI(productVersionId)}&lineOfBusinessItemId=${encodeURI(lobItemId)}`;

const QUERY_PRODUCT_VERSION_FLOW_SUMMARY_BY_PRODUCT_VERSION_ID = (
  productVersionId: string
) =>
  `${SERVER_URL}/admin/prod/productversionflow/querysummarybyproductversionid?productVersionId=${encodeURI(productVersionId)}`;

const GET_PRODUCT_VERSION_FLOW_URL = (id: string) =>
  `${SERVER_URL}/admin/prod/productversionflow/get/${id}`;
const SAVE_PRODUCT_VERSION_FLOW_URL = `${SERVER_URL}/admin/prod/productversionflow/update`;
const DELETE_PRODUCT_VERSION_FLOW_URL = (id: string) =>
  `${SERVER_URL}/admin/prod/productversionflow/delete/${id}`;
const COPY_PRODUCT_VERSION_RISK_FLOW_URL = `${SERVER_URL}/admin/prod/productversionflow/actions/copyRiskFlow`;

const QUERY_PRODUCT_VERSION_TEST_CASES_BY_VERSION_URL = (
  productVersionId: string
) =>
  `${SERVER_URL}/admin/prod/productversiontestcase/queryinfobyproductversionid?productVersionId=${encodeURI(productVersionId)}`;
const GET_PRODUCT_VERSION_TEST_CASE_URL = (id: string) =>
  `${SERVER_URL}/admin/prod/productversiontestcase/get/${id}`;
const SAVE_PRODUCT_VERSION_TEST_CASE_URL = `${SERVER_URL}/admin/prod/productversiontestcase/update`;
const DELETE_PRODUCT_VERSION_TEST_CASE_URL = (id: string) =>
  `${SERVER_URL}/admin/prod/productversiontestcase/delete/${id}`;

const GET_LOB_HIERARCHY_BY_PRODUCT_VERSION_ID_URL = (id: string) =>
  `${SERVER_URL}/admin/prod/lobhierarchybyproductversionid/get/${id}`;

const QUERY_LOB_INFO_CARRIER = `${SERVER_URL}/admin/prod/productlobinfo/carrier`;
const QUERY_LOB_INFO_RSO = `${SERVER_URL}/admin/prod/productlobinfo/rso`;

export const UPLOAD_ANALYSIS_DOCUMENT_URL = `${SERVER_URL}/admin/document`;

export interface ProductService {
  QueryProduct(productQuery: ProductQuery): Promise<ProductSummary[]>;
  GetProduct(id: string): Promise<Product>;
  CreateProduct(
    product: Product,
    version: ProductVersion,
    notes: ProductVersionNote[],
    importTables: boolean,
    documentId: string | undefined
  ): Promise<Product>;

  UpdateProduct(product: Product): Promise<Product>;
  UpdateProductAndVersion(
    productId: string,
    productVersionId: string,
    underwritingCompanyId: string,
    productTypeId: string,
    lobHierarchyId: string,
    stateCode: string,
    effectiveDate: string,
    description: string
  ): Promise<Product>;

  DeleteProduct(id: string): Promise<void>;

  GetProductVersionsByProductId(productId: string): Promise<ProductVersion[]>;
  GetProductVersionSummaryById(id: string): Promise<ProductVersionSummary>;
  QueryProductVersionSummaries(
    query: ProductVersionQuery
  ): Promise<ProductVersionSummary[]>;
  GetProductVersion(id: string): Promise<ProductVersion>;
  UpdateProductVersion(productVersion: ProductVersion): Promise<ProductVersion>;
  DeleteProductVersion(id: string): Promise<void>;
  CopyProductVersion(id: string, description: string): Promise<ProductVersion>;
  CopyProductVersionNewEffectiveDate(
    id: string,
    effectiveDate: string,
    newVersionRequired: boolean,
    description: string
  ): Promise<ProductVersion>;
  CopyProductVersionNewProduct(
    id: string,
    underwritingCompanyValueListItemId: string | undefined,
    effectiveDate: string,
    stateCode: string,
    description: string
  ): Promise<ProductVersion>;
  PublishProductVersion(id: string, note: string): Promise<ProductVersion>;
  GetProductVersionCoveragesByProductVersionId(
    productVersionId: string
  ): Promise<ProductVersionCoverage[]>;
  GetProductVersionCoverage(id: string): Promise<ProductVersionCoverage>;
  UpdateProductVersionCoverage(
    productVersionCoverage: ProductVersionCoverage
  ): Promise<ProductVersionCoverage>;
  DeleteProductVersionCoverage(id: string): Promise<void>;

  GetProductVersionCoverageFieldsByCoverageId(
    coverageId: string
  ): Promise<ProductVersionCoverageField[]>;
  GetProductVersionCoverageField(
    id: string
  ): Promise<ProductVersionCoverageField>;
  UpdateProductVersionCoverageField(
    productVersionCoverageField: ProductVersionCoverageField
  ): Promise<ProductVersionCoverageField>;
  DeleteProductVersionCoverageField(id: string): Promise<void>;
  GetProductVersionNotesByProductVersionId(
    productVersionId: string
  ): Promise<ProductVersionNote[]>;
  GetProductVersionNote(id: string): Promise<ProductVersionNote>;
  UpdateProductVersionNote(
    productVersionNote: ProductVersionNote
  ): Promise<ProductVersionNote>;
  DeleteProductVersionNote(id: string): Promise<void>;
  GetProductVersionTableNamesByProductVersionId(
    productVersionId: string
  ): Promise<IdAndName[]>;
  GetProductVersionTable(id: string): Promise<Table>;
  GetProductVersionTableWithoutRows(id: string): Promise<TableDefinition>;
  UpdateProductVersionTable(
    productVersionId: string,
    table: Table
  ): Promise<Table>;
  DeleteProductVersionTable(id: string): Promise<void>;

  GetProductVersionFlow(id: string): Promise<Flow>;
  GetProductVersionFlowNamesByVersionIdAndLobItemId(
    versionId: string,
    lobItemId: string
  ): Promise<IdAndName[]>;
  GetProductVersionFlowsByVersionIdAndLobItemId(
    versionId: string,
    lobItemId: string
  ): Promise<Flow[]>;
  GetProductVersionFlowSummariesByProductVersionId(
    productVersionId: string
  ): Promise<ProductVersionFlowInfo[]>;
  UpdateProductVersionFlow(flow: Flow): Promise<Flow>;
  DeleteProductVersionFlow(id: string): Promise<void>;
  CopyProductVersionRiskFlow(
    sourceFlowId: string,
    newFlowName: string
  ): Promise<string>;

  GetProductVersionTestCase(id: string): Promise<TestCase>;
  GetProductVersionTestCaseInfosByVersionId(
    versionId: string
  ): Promise<TestCaseInfo[]>;
  UpdateProductVersionTestCase(testCase: TestCase): Promise<TestCase>;
  DeleteProductVersionTestCase(id: string): Promise<void>;

  GetProductVersionTableDocumentTableMetadataByProductVersionTableId(
    productVersionTableId: string
  ): Promise<ProductVersionTableMetadataSummary[]>;

  GetLobHierarchyForProductVersionId(
    productVersionId: string
  ): Promise<LineOfBusinessHierarchy>;

  GetLobInfoForCarrier: () => Promise<ProductLobInfo[]>;
  GetLobInfoForRso: () => Promise<ProductLobInfo[]>;

  GetProductVersionActivityHistoryByProductVersionId: (
    productVersionId: string
  ) => Promise<ProductVersionActivityHistory[]>;
}

export default function NewProductService(authToken: string): ProductService {
  function createHeaders() {
    return CreateStandardHeaders(authToken);
  }

  async function QueryProduct(
    productQuery: ProductQuery
  ): Promise<ProductSummary[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(QUERY_PRODUCT_URL(productQuery), requestOptions)
    );
  }

  async function GetProduct(id: string): Promise<Product> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(fetch(GET_PRODUCT_URL(id), requestOptions));
  }

  async function CreateProduct(
    product: Product,
    version: ProductVersion,
    notes: ProductVersionNote[],
    importTables: boolean,
    documentId: string | undefined
  ): Promise<Product> {
    const args = {
      product: product,
      version: version,
      notes: notes,
      importTables: importTables,
      documentId: documentId,
    };

    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify(args),
    };

    return HandleFetchJson(fetch(CREATE_PRODUCT_URL, requestOptions));
  }

  async function UpdateProduct(product: Product): Promise<Product> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify(product),
    };

    return HandleFetchJson(fetch(SAVE_PRODUCT_URL, requestOptions));
  }

  async function DeleteProduct(id: string): Promise<void> {
    const requestOptions = {
      method: 'DELETE',
      headers: createHeaders(),
    };

    return HandleFetchVoid(fetch(DELETE_PRODUCT_URL(id), requestOptions));
  }

  async function UpdateProductAndVersion(
    productId: string,
    productVersionId: string,
    underwritingCompanyId: string,
    productTypeId: string,
    lobHierarchyId: string,
    stateCode: string,
    effectiveDate: string,
    description: string
  ): Promise<Product> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify({
        productId: productId,
        productVersionId: productVersionId,
        underwritingCompanyId: underwritingCompanyId,
        productTypeId: productTypeId,
        lobHierarchyId: lobHierarchyId,
        stateCode: stateCode,
        effectiveDate: effectiveDate,
        description: description,
      }),
    };

    return HandleFetchJson(
      fetch(UPDATE_PRODUCT_AND_VERSION_URL, requestOptions)
    );
  }

  async function GetProductVersionsByProductId(
    productId: string
  ): Promise<ProductVersion[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(QUERY_PRODUCT_VERSION_BY_PRODUCT_URL(productId), requestOptions)
    );
  }

  async function GetProductVersionSummaryById(
    productVersionId: string
  ): Promise<ProductVersionSummary> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(
        QUERY_PRODUCT_VERSION_SUMMARY_BY_ID_URL(productVersionId),
        requestOptions
      )
    );
  }

  async function QueryProductVersionSummaries(
    query: ProductVersionQuery
  ): Promise<ProductVersionSummary[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(QUERY_PRODUCT_VERSION_SUMMARY_URL(query), requestOptions)
    );
  }

  async function GetProductVersion(id: string): Promise<ProductVersion> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(fetch(GET_PRODUCT_VERSION_URL(id), requestOptions));
  }

  async function UpdateProductVersion(
    product: ProductVersion
  ): Promise<ProductVersion> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify(product),
    };

    return HandleFetchJson(fetch(SAVE_PRODUCT_VERSION_URL, requestOptions));
  }

  async function DeleteProductVersion(id: string): Promise<void> {
    const requestOptions = {
      method: 'DELETE',
      headers: createHeaders(),
    };

    return HandleFetchVoid(
      fetch(DELETE_PRODUCT_VERSION_URL(id), requestOptions)
    );
  }

  async function CopyProductVersion(
    id: string,
    description: string
  ): Promise<ProductVersion> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify({
        productVersionId: id,
        description: description,
      }),
    };

    return HandleFetchJson(fetch(COPY_PRODUCT_VERSION_URL, requestOptions));
  }

  async function CopyProductVersionNewEffectiveDate(
    id: string,
    effectiveDate: string,
    newVersionRequired: boolean,
    description: string
  ): Promise<ProductVersion> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify({
        productVersionId: id,
        effectiveDate: effectiveDate,
        newVersionRequired: newVersionRequired,
        description: description,
      }),
    };

    return HandleFetchJson(
      fetch(COPY_PRODUCT_VERSION_NEW_EFFECTIVE_DATE_URL, requestOptions)
    );
  }

  async function CopyProductVersionNewProduct(
    id: string,
    underwritingCompanyValueListItemId: string,
    effectiveDate: string,
    stateCode: string,
    description: string
  ): Promise<ProductVersion> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify({
        productVersionId: id,
        underwritingCompanyValueListItemId: underwritingCompanyValueListItemId,
        effectiveDate: effectiveDate,
        stateCode: stateCode,
        description: description,
      }),
    };

    return HandleFetchJson(
      fetch(COPY_PRODUCT_VERSION_NEW_PRODUCT_URL, requestOptions)
    );
  }

  async function PublishProductVersion(
    id: string,
    note: string
  ): Promise<ProductVersion> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify({
        productVersionId: id,
        note: note,
      }),
    };

    return HandleFetchJson(fetch(PUBLISH_PRODUCT_VERSION_URL, requestOptions));
  }

  async function GetProductVersionCoveragesByProductVersionId(
    productVersionId: string
  ): Promise<ProductVersionCoverage[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(
        QUERY_PRODUCT_VERSION_COVERAGE_URL(productVersionId),
        requestOptions
      )
    );
  }

  async function GetProductVersionCoverage(
    id: string
  ): Promise<ProductVersionCoverage> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(GET_PRODUCT_VERSION_COVERAGE_URL(id), requestOptions)
    );
  }

  async function UpdateProductVersionCoverage(
    product: ProductVersionCoverage
  ): Promise<ProductVersionCoverage> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify(product),
    };

    return HandleFetchJson(
      fetch(SAVE_PRODUCT_VERSION_COVERAGE_URL, requestOptions)
    );
  }

  async function DeleteProductVersionCoverage(id: string): Promise<void> {
    const requestOptions = {
      method: 'DELETE',
      headers: createHeaders(),
    };

    return HandleFetchVoid(
      fetch(DELETE_PRODUCT_VERSION_COVERAGE_URL(id), requestOptions)
    );
  }

  async function GetProductVersionCoverageFieldsByCoverageId(
    coverageId: string
  ): Promise<ProductVersionCoverageField[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(
        QUERY_PRODUCT_VERSION_COVERAGE_FIELD_URL(coverageId),
        requestOptions
      )
    );
  }

  async function GetProductVersionCoverageField(
    id: string
  ): Promise<ProductVersionCoverageField> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(GET_PRODUCT_VERSION_COVERAGE_FIELD_URL(id), requestOptions)
    );
  }

  async function UpdateProductVersionCoverageField(
    product: ProductVersionCoverageField
  ): Promise<ProductVersionCoverageField> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify(product),
    };

    return HandleFetchJson(
      fetch(SAVE_PRODUCT_VERSION_COVERAGE_FIELD_URL, requestOptions)
    );
  }

  async function DeleteProductVersionCoverageField(id: string): Promise<void> {
    const requestOptions = {
      method: 'DELETE',
      headers: createHeaders(),
    };

    return HandleFetchVoid(
      fetch(DELETE_PRODUCT_VERSION_COVERAGE_FIELD_URL(id), requestOptions)
    );
  }

  async function GetProductVersionNotesByProductVersionId(
    productVersionId: string
  ): Promise<ProductVersionNote[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(QUERY_PRODUCT_VERSION_NOTE_URL(productVersionId), requestOptions)
    );
  }

  async function GetProductVersionNote(
    id: string
  ): Promise<ProductVersionNote> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(GET_PRODUCT_VERSION_NOTE_URL(id), requestOptions)
    );
  }

  async function UpdateProductVersionNote(
    product: ProductVersionNote
  ): Promise<ProductVersionNote> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify(product),
    };

    return HandleFetchJson(
      fetch(SAVE_PRODUCT_VERSION_NOTE_URL, requestOptions)
    );
  }

  async function DeleteProductVersionNote(id: string): Promise<void> {
    const requestOptions = {
      method: 'DELETE',
      headers: createHeaders(),
    };

    return HandleFetchVoid(
      fetch(DELETE_PRODUCT_VERSION_NOTE_URL(id), requestOptions)
    );
  }

  async function GetProductVersionTableNamesByProductVersionId(
    productVersionId: string
  ): Promise<IdAndName[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(
        QUERY_PRODUCT_VERSION_TABLE_NAMES_URL(productVersionId),
        requestOptions
      )
    );
  }

  async function GetProductVersionTable(id: string): Promise<Table> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(GET_PRODUCT_VERSION_TABLE_URL(id), requestOptions)
    );
  }

  async function GetProductVersionTableWithoutRows(
    id: string
  ): Promise<TableDefinition> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(GET_PRODUCT_VERSION_TABLE_WITHOUT_ROWS_URL(id), requestOptions)
    );
  }

  async function UpdateProductVersionTable(
    productVersionId: string,
    table: Table
  ): Promise<Table> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify({
        productVersionId: productVersionId,
        table: table,
      }),
    };

    return HandleFetchJson(
      fetch(SAVE_PRODUCT_VERSION_TABLE_URL, requestOptions)
    );
  }

  async function DeleteProductVersionTable(id: string): Promise<void> {
    const requestOptions = {
      method: 'DELETE',
      headers: createHeaders(),
    };

    return HandleFetchVoid(
      fetch(DELETE_PRODUCT_VERSION_TABLE_URL(id), requestOptions)
    );
  }

  async function GetProductVersionFlow(id: string): Promise<Flow> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(GET_PRODUCT_VERSION_FLOW_URL(id), requestOptions)
    );
  }

  async function GetProductVersionFlowNamesByVersionIdAndLobItemId(
    versionId: string,
    lobItemId: string
  ): Promise<IdAndName[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(
        QUERY_PRODUCT_VERSION_FLOW_NAMES_LOB_URL(versionId, lobItemId),
        requestOptions
      )
    );
  }

  async function GetProductVersionFlowsByVersionIdAndLobItemId(
    versionId: string,
    lobItemId: string
  ): Promise<Flow[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(
        QUERY_PRODUCT_VERSION_FLOW_BY_VERSION_LOB_URL(versionId, lobItemId),
        requestOptions
      )
    );
  }

  async function GetProductVersionFlowSummariesByProductVersionId(
    productVersionId: string
  ): Promise<ProductVersionFlowInfo[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(
        QUERY_PRODUCT_VERSION_FLOW_SUMMARY_BY_PRODUCT_VERSION_ID(
          productVersionId
        ),
        requestOptions
      )
    );
  }

  async function UpdateProductVersionFlow(flow: Flow): Promise<Flow> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify(flow),
    };

    return HandleFetchJson(
      fetch(SAVE_PRODUCT_VERSION_FLOW_URL, requestOptions)
    );
  }

  async function DeleteProductVersionFlow(id: string): Promise<void> {
    const requestOptions = {
      method: 'DELETE',
      headers: createHeaders(),
    };

    return HandleFetchVoid(
      fetch(DELETE_PRODUCT_VERSION_FLOW_URL(id), requestOptions)
    );
  }

  async function CopyProductVersionRiskFlow(
    sourceFlowId: string,
    newFlowName: string
  ): Promise<string> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify({
        sourceFlowId: sourceFlowId,
        newFlowName: newFlowName,
      }),
    };

    return HandleFetchText(
      fetch(COPY_PRODUCT_VERSION_RISK_FLOW_URL, requestOptions)
    );
  }

  async function GetProductVersionTestCase(id: string): Promise<TestCase> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(GET_PRODUCT_VERSION_TEST_CASE_URL(id), requestOptions)
    );
  }

  async function GetProductVersionTestCaseInfosByVersionId(
    versionId: string
  ): Promise<TestCaseInfo[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(
        QUERY_PRODUCT_VERSION_TEST_CASES_BY_VERSION_URL(versionId),
        requestOptions
      )
    );
  }

  async function UpdateProductVersionTestCase(
    testCase: TestCase
  ): Promise<TestCase> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify(testCase),
    };

    return HandleFetchJson(
      fetch(SAVE_PRODUCT_VERSION_TEST_CASE_URL, requestOptions)
    );
  }

  async function DeleteProductVersionTestCase(id: string): Promise<void> {
    const requestOptions = {
      method: 'DELETE',
      headers: createHeaders(),
    };

    return HandleFetchVoid(
      fetch(DELETE_PRODUCT_VERSION_TEST_CASE_URL(id), requestOptions)
    );
  }

  async function GetProductVersionTableDocumentTableMetadataByProductVersionTableId(
    productVersionTableId: string
  ): Promise<ProductVersionTableMetadataSummary[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(
        QUERY_PRODUCT_VERSION_TABLE_NAME_DOCUMENT_TABLE_METADATA_URL(
          productVersionTableId
        ),
        requestOptions
      )
    );
  }

  async function GetLobHierarchyForProductVersionId(
    productVersionId: string
  ): Promise<LineOfBusinessHierarchy> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(
        GET_LOB_HIERARCHY_BY_PRODUCT_VERSION_ID_URL(productVersionId),
        requestOptions
      )
    );
  }

  async function GetLobInfoForCarrier(): Promise<ProductLobInfo[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    const response = await fetch(QUERY_LOB_INFO_CARRIER, requestOptions);
    if (!response.ok) {
      console.log(response.body);
      return Promise.reject(
        new HttpError(response.status, await response.text())
      );
    }

    return JSON.parse(await response.text()) as ProductLobInfo[];
  }

  async function GetLobInfoForRso(): Promise<ProductLobInfo[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(fetch(QUERY_LOB_INFO_RSO, requestOptions));
  }

  async function GetProductVersionActivityHistoryByProductVersionId(
    productVersionId: string
  ): Promise<ProductVersionActivityHistory[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(QUERY_ACTIVITY_HISTORY_URL(productVersionId), requestOptions)
    );
  }

  return {
    QueryProduct,
    GetProduct,
    CreateProduct,
    UpdateProduct,
    DeleteProduct,

    UpdateProductAndVersion,

    GetProductVersionsByProductId,
    GetProductVersionSummaryById,
    QueryProductVersionSummaries,
    GetProductVersion,
    UpdateProductVersion,
    DeleteProductVersion,
    CopyProductVersion,
    CopyProductVersionNewEffectiveDate,
    CopyProductVersionNewProduct,
    PublishProductVersion,

    GetProductVersionCoveragesByProductVersionId,
    GetProductVersionCoverage,
    UpdateProductVersionCoverage,
    DeleteProductVersionCoverage,

    GetProductVersionCoverageFieldsByCoverageId,
    GetProductVersionCoverageField,
    UpdateProductVersionCoverageField,
    DeleteProductVersionCoverageField,

    GetProductVersionNotesByProductVersionId,
    GetProductVersionNote,
    UpdateProductVersionNote,
    DeleteProductVersionNote,

    GetProductVersionTableNamesByProductVersionId,
    GetProductVersionTable,
    GetProductVersionTableWithoutRows,
    UpdateProductVersionTable,
    DeleteProductVersionTable,

    GetProductVersionFlow,
    GetProductVersionFlowsByVersionIdAndLobItemId,
    GetProductVersionFlowNamesByVersionIdAndLobItemId,
    GetProductVersionFlowSummariesByProductVersionId,
    UpdateProductVersionFlow,
    DeleteProductVersionFlow,
    CopyProductVersionRiskFlow,

    GetProductVersionTestCase,
    GetProductVersionTestCaseInfosByVersionId,
    UpdateProductVersionTestCase,
    DeleteProductVersionTestCase,
    GetProductVersionTableDocumentTableMetadataByProductVersionTableId,

    GetLobHierarchyForProductVersionId,

    GetLobInfoForCarrier,
    GetLobInfoForRso,

    GetProductVersionActivityHistoryByProductVersionId,
  };
}
